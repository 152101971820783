import abi from "./contract/GlobalPool.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Buy from "./component/buy"



import "./copy.png";
import Buy3 from "./component/buy3";




function App() {
  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[pack,setpackage]=useState("None");
  const[pack1,setpackage1]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [tasks1,setTasks1] = useState([]);
const [tt,settt]=useState("None");
const[dd,setDD]=useState("None");
const[dd1,setDD1]=useState("None");
const[dd2,setDD2]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[upd,setupd]=useState("None");
const [tt1,settt1]=useState("None");
const [roi,seroi]=useState("None");
const [roi1,seroi1]=useState("None");
const [roi2,seroi2]=useState("None");
const [a1,sea1]=useState("None");
const [a2,sea2]=useState("None");
const [a3,sea3]=useState("None");
const [tt2,settt2]=useState("None");
const [tt3,settt3]=useState("None");

  useEffect(() => {
    
    const connectWallet = async () => {
      
      const contractAddress = "0x7639b08fd622dd7795c13a5179d27fb83d674983";
      const contractABI = abi.abi;
     
      const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    document.querySelector("#name").value=myParam;
if(myParam == null)
  {
    document.querySelector("#name").value="0xb1aE4c30f9253B347D9338d115b90f9354324CEA";
  }
  
  try {
    const { ethereum } = window;
    document.querySelector("#test").value = "Enter Amount";
    if (ethereum) {
      const account = await ethereum.request({
        method: "eth_requestAccounts",
       
      });
     
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      window.ethereum.on("accountsChanged", () => {
        
        window.location.reload();
      });
          const provider = new ethers.providers.Web3Provider(ethereum);
        
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
        
        
        
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
      let alltasks = await contract.getMemos(account[0]);
      setTasks(alltasks);
      let alltasks1 = await contract.getMemos1(account[0]);
      setTasks1(alltasks1);
      const b =Number(alltasks[2]);
      const refer = (alltasks[1]);
     setrefer(refer);
     document.querySelector("#hid").value  = (alltasks[1]);
     const direct =Number(alltasks[2]);
     setDirect(direct);
     const directs = Number(alltasks[3])/1000000000000000000;
     setDirects(directs);
     const level = Number(alltasks[4])/1000000000000000000;
     setLevel(level);
     
    
     const pack = Number(alltasks[6])/1000000000000000000 ;
     

setpackage(pack);


const pack1 = Number(alltasks[5]) ;
document.querySelector("#pp").value  = Number(alltasks[5]);


setpackage1(pack1);



     setB(b);

     const upd = (Number(alltasks[7]) ) /1000000000000000000  ;
          
     setupd(upd);

     const dd = Number(alltasks[9]);
          
     setDD(dd);

     const dd2 = Number(alltasks[9]);
          
     setDD2(dd2);

     const dd1 =  Date.now() / 1000;
     setDD1(dd1);


     const tt1 = Math.trunc((dd1 - dd) / (3660 * 24 )) ;
   //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
     
     settt1(tt1);

     const tt2 = Math.trunc((dd1 - dd2) / (3600 * 24  )) ;
     //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
       
       settt2(tt2);
      
       document.querySelector("#tt2").value = tt2;
       
       

       
    // const roi1 = Number(alltasks[9]) / 100000;
          
    // seroi1(roi1);

    

    // document.querySelector("#wi1").value = roi2;
     
     const a1 = Number(alltasks1[0])-1;
          
     sea1(a1);
     const a3 = Number(alltasks1[2])/1000000000000000000;
          
     sea3(a3);
     const a2 = Number(alltasks1[1])/1000000000000000000;
     sea2(a2);   
     var rr = 0;
     
     
     const tt =  level  + directs;
     settt(tt);
     const tt3 = Number(alltasks[10])/1000000000000000000;
     settt3(tt3);
     document.querySelector("#bal").value = tt3;
     const referlink = 'https://magiccoin.live/?ref='+ account;
     setreflink(referlink);
     document.querySelector("#link").value =referlink;

console.log(pack);
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
   
    
    connectWallet();
    
    function myFunction() {
      
      var copyText = "hello";
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      
    
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.valueOf);
    }

  }, []);

 
  // console.log(state);
  return (
  <div>
    <input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
    <input type="hidden" id ="tt2"></input>
    <input type="hidden" id ="wi1"></input>
    <input type="hidden" id ="roi"></input>
    <input type="hidden" id ="bal"></input>
<div class="se-pre-con"></div>
  
            <div id="content">
            
      <div className="container">
       
   
</div></div>
<div class="container-fluid">

<div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}><img src ="logo.png" width="250px" height="150px"></img>
</p>
                            </div>
                           
                        </div>
    </div>
    </div> <br></br><br></br>

<div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>1 MCT = {a3} USDT
                            </p>
                            </div>
                           
                           <br></br><br></br>
                        </div>
    </div>
    </div>

    <br></br><br></br>  
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>TOTAL TOKEN HOLDERS</p>
                            </div>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>  {a1}</p>
                           <br></br><br></br>
                        </div>
    </div>
    </div>

    <br></br><br></br>      <div class="row">
                   
                    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>WALLET ADDRESS</p>
                            </div>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                            Connected Account - {account}</p>
                           <br></br><br></br>
                        </div>
    </div>
    </div>


<br></br><br></br>


    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>MY REFER</p>
                            </div>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>  {refer}</p>
                           <br></br><br></br>
                        </div>
    </div>
    </div>
    <br></br><br></br>
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                               <br></br> <br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>MY REFER LINK</p>
                            </div>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>   {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
         navigator.clipboard.writeText(referlink);}} /></button></p>
         <br></br><br></br>
                        </div>
    </div>
    </div>
    <br></br><br></br>
    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>MY TOKENS</p>
                            </div>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                             {pack} MCT
                             </p>
                             <br></br><br></br>
                        </div>
    </div>
    </div>
<br></br><br></br>
<div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                                <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>MY PURCHASE</p>
                            </div>

                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                             {pack1} USDT
                             </p>
                             <br></br><br></br>
                        </div>
    </div>
    </div>
<br></br><br></br>

    <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>MY DIRECT TEAM</p>
                            </div>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                             {direct}</p>
                             <br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>      <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                               <br></br><br></br>  
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>MY DIRECT INCOME</p>
                            </div>                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                             {directs} USDT</p><br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>      <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>MY LEVEL INCOME</p>
                            </div>                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                             {level} USDT </p>
                            <br></br><br></br>                        </div>
    </div>
    </div>
<br></br><br></br>   
   <div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>TOTAL BONUS</p>
                            </div>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                             {tt} MCT </p> <br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>     

<div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>TOTAL WITHDRAW</p>
                            </div>
                            <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>         {upd} USDT </p> <br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>     
<div class="row">
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>TOTAL COIN WITHDRAW</p>
                            </div>                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                             {tt3} MCT </p> <br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>    
 <div class="row">
    
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>PURCHASE TOKENS</p>
                            </div>                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                            <input type ="text" id ="test" width={"200px"} ></input>
                            <br></br><br></br>
                            <Buy state={state}  /> </p><br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br> 
<br></br><br></br>    
 <div class="row">
    
    <div class="outer-w3-agile col-xl" style={{
                      backgroundColor: "#191970",
                      border: "1px solid white",
                      borderCollapse: "collapse",
                      padding: "7px",
                      width: "100%",
                    }}>
                        <div class="stat-grid p-3 d-flex align-items-center justify-content-between bg-primary" >
                            <div class="s-l" >
                            <br></br><br></br>
                                                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>WITHDRAW TOKENS</p>
                            </div>                         <p style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
                            <input type ="text" id ="test1" width={"200px"} ></input>
                            <br></br><br></br>
                            <Buy3 state={state}  /> </p><br></br><br></br>
                        </div>
    </div>
    </div>

<br></br><br></br>

</div>
  
    </div>
  
  
  );
}

export default App;